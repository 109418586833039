import { createI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
// @ts-expect-error
import elementSkLocale from 'element-plus/dist/locale/sk.mjs'; // element-plus lang
// @ts-expect-error
import elementEnLocale from 'element-plus/dist/locale/en.mjs'; // element-plus lang
import skLocale from './sk.json';
import enLocale from './en.json';

const messages: Record<string, unknown> = {
	sk: {
		...skLocale,
		...elementSkLocale,
	},
	en: {
		...enLocale,
		...elementEnLocale,
	},
};

export function getLanguage() {
	const chooseLanguage = Cookies.get('language') as 'sk' | 'en';
	return chooseLanguage ? chooseLanguage : 'sk';
}

const pluralRules = {
	/**
	 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
	 * @param choicesLength {number} an overall amount of available choices
	 * @returns a final choice index to select plural word by
	 */
	// eslint-disable-next-line
	sk: function(choice: number) {
		return (choice === 0 ? 2 : (choice === 1 ? 0 : (choice <= 4 ? 1 : 2)));
	},
	en: (choice: number) => (choice === 1 ? 0 : 1),
};

const i18n = createI18n({
	locale: 'sk',
	fallbackLocale: 'sk',
	legacy: false,
	messages,
	globalInjection: true,
	pluralizationRules: pluralRules,
	// eslint-disable-next-line
	pluralRules: pluralRules,
});

// i18n.global.tc = i18n.global.t; - If there is tc() called somewhere, enable this (old libs maybe)

export default i18n;
