import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { IconHomeBolt } from '@tabler/icons-vue';
import { defineAsyncComponent } from 'vue';

export const energyRoutes: RouterRecord = {
	path: '/energy',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'energy', icon: IconHomeBolt, group: 'modules', id: UserModuleIdentifiers.ENERGY },
	name: RouteNames.ENERGY,
	children: [
		{
			path: '',
			component: defineAsyncComponent(async () => import('@/views/energy/EnergyDashboard.vue')),
			name: RouteNames.ENERGY_DASHBOARD,
		},
		{
			path: 'objects/:id',
			component: defineAsyncComponent(async () => import('@/views/energy/EnergyObject.vue')),
			name: RouteNames.ENERGY_OBJECT,
		},
		{
			path: 'supply-points/:id',
			component: defineAsyncComponent(async () => import('@/views/energy/EnergySupplyPoint.vue')),
			name: RouteNames.ENERGY_SUPPLY_POINT,
		},
	],
};
