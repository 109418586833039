import { GlobalSettings } from '@/settings';
import i18n from '@/lang';

const { t, te } = i18n.global;

const title = GlobalSettings.title || 'Laravel Vue Admin';

export default function getPageTitle(key: string) {
	const hasKey = te(`route.${key}`);
	if (hasKey) {
		const pageName = t(`route.${key}`);
		return `${pageName} - ${title}`;
	}

	return `${title}`;
}
