import Icon from '@/assets/icons/broadcast.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const broadcastRoutes: RouterRecord = {
	path: '/broadcast',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'broadcast', icon: Icon, group: 'modules', id: UserModuleIdentifiers.BROADCAST },
	name: RouteNames.BROADCAST_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			name: RouteNames.BROADCAST,
		},
	],
};
