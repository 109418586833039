import Icon from '@/assets/icons/admin.svg';
import SubIcon from '@/assets/icons/circle_sidebar.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { AdminModuleIdentifiers } from '@/types/store/user';

export const adminSystemSettingsRoutes: RouterRecord = {
	path: '/system',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/system/locality',
	name: RouteNames.ADMIN_SYSTEM_ROOT,
	meta: {
		title: 'system_settings',
		icon: Icon,
		group: 'admin',
		isNotClickable: true,
		roles: ['superadmin'],
	},
	children: [
		{
			path: 'locality',
			component: async () => import('@/views/locality/LocalityIndex.vue'),
			name: RouteNames.ADMIN_SYSTEM_LOCALITY,
			meta: { title: 'localityManagement', icon: SubIcon, subItem: 'system-admin-first', id: AdminModuleIdentifiers.LOCALITY },
			children: [
				{
					path: ':locality_id(\\d+)',
					component: async () => import('@/views/locality/LocalityIndex.vue'),
					name: RouteNames.ADMIN_SYSTEM_SUB_LOCALITY,
				},
			],
		},
		{
			path: 'modules',
			component: async () => import('@/views/locality/LocalityModules.vue'),
			name: RouteNames.ADMIN_SYSTEM_MODULES,
			meta: { title: 'moduleManager', icon: SubIcon, subItem: 'system-admin-second', id: AdminModuleIdentifiers.MODULES },
		},
		{
			path: 'categories',
			component: async () => import('@/views/categories/CategoryIndex.vue'),
			name: RouteNames.ADMIN_SYSTEM_CATEGORIES,
			meta: { title: 'categoryManager', icon: SubIcon, subItem: 'system-admin-third', id: AdminModuleIdentifiers.CATEGORIES },
			children: [
				{
					path: '',
					component: async () => import('@/views/categories/CategoryIndex.vue'),
					name: RouteNames.ADMIN_SYSTEM_MODULE_DEFAULT,
					meta: { title: 'categories', icon: 'categories', root: true },
				},
				{
					path: ':module_id(\\d+)',
					component: async () => import('@/views/categories/CategoryIndex.vue'),
					name: RouteNames.ADMIN_SYSTEM_MODULE_CATEGORIES,
				},
			],
		},
		{
			path: 'subcategory/:parent_id(\\d+)',
			component: async () => import('@/views/categories/SubCategories.vue'),
			name: RouteNames.ADMIN_SYSTEM_MODULE_SUBCATEGORIES,
			meta: { id: AdminModuleIdentifiers.CATEGORIES },
		},
	],
};
