import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';

export const dashboardRootRoute: RouterRecord = {
	path: '/',
	component: async () => import('@/layout/LayoutIndex.vue'),
	name: RouteNames.DASHBOARD_ROOT,
	meta: { title: 'home' },
	children: [
		{
			path: '',
			component: async () => import('@/views/dashboard/DashboardNewIndex.vue'),
			name: RouteNames.DASHBOARD,
			meta: { title: 'home', icon: 'dashboard' },
		},
	],
};
