export interface IGlobalSettings {
	title: string;
	showSettings: boolean;
	tagsView: boolean;
	fixedHeader: boolean;
	sidebarLogo: boolean;
	errorLog: 'production' | ['production', 'development'];
	numberLevels: number;
}

export const GlobalSettings: IGlobalSettings = {
	title: 'Antik SmartCITY Admin',
	/** @description Whether show the settings right-panel */
	showSettings: false,
	/** @description Whether need tagsView */
	tagsView: false,
	/** @description Whether fix the header */
	fixedHeader: false,
	/** @description Whether show the logo in sidebar */
	sidebarLogo: true,
	/** @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production','development']
   */
	errorLog: 'production',
	/** @description value used to display the number of levels */
	numberLevels: 2,
};
