import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { dashboardRootRoute } from '@/router/routes/dashboardRoute';
import { adminUserSettingsRoutes } from '@/router/routes/admin_user_settings';
import { adminSystemSettingsRoutes } from '@/router/routes/admin_system_setting';
import { lightingRoutes } from '@/router/routes/lighting';
import { parkingRoutes } from '@/router/routes/parking';
import { wasteRoutes } from '@/router/routes/waste';
import { broadcastRoutes } from '@/router/routes/broadcast';
import { trafficRoutes } from '@/router/routes/traffic';
import { informationMediaRoutes } from '@/router/routes/informationMedia';
import { errorRoutes } from '@/router/routes/error';
import { airQualityRoutes } from '@/router/routes/air_quality';
import { chargingStationsRoutes } from '@/router/routes/charging_stations';
import { energyRoutes } from '@/router/routes/energy';
import { environmentRoutes } from '@/router/routes/environment';
import { bikeSharingRoutes } from '@/router/routes/bike_sharing';
import { scooterSharingRoutes } from '@/router/routes/scooter_sharing';
import { pointOfInterestRoutes } from '@/router/routes/poi';
import { eventsRoutes } from '@/router/routes/events';
import { routesRoutes } from '@/router/routes/routes';
import { applicationsRoutes } from '@/router/routes/applications';
import { ticketsRoutes } from '@/router/routes/tickets';
import { articleRoutes } from '@/router/routes/articles';
import { camerasRoutes } from '@/router/routes/cameras';
import { alertsRoutes } from '@/router/routes/alerts';
import { cantUserEnterLoginPage } from '@/utils/auth';

export const constantRoutes: RouterRecord[] = [
	{
		name: RouteNames.LOGIN,
		path: '/login',
		component: async () => import('@/views/login/LoginIndex.vue'),
		async beforeEnter() {
			if (cantUserEnterLoginPage()) {
				await router.push('/');
			}
		},
	},
	{
		path: '/:pathMatch(.*)*',
		component: async () => import('@/views/error-page/NotFound404.vue'),
		name: RouteNames.E404,
	},
	{
		path: '/401',
		component: async () => import('@/views/error-page/UnAuthorized401.vue'),
		name: RouteNames.E401,
	},
	{
		path: '/429',
		component: async () => import('@/views/error-page/TooManyRequests429.vue'),
		name: RouteNames.E429,
	},
	dashboardRootRoute,
	{
		path: '/maintenance',
		component: async () => import('@/layout/LayoutIndex.vue'),
		name: RouteNames.MAINTENANCE_ROOT,
		children: [
			{
				path: '',
				component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
				name: RouteNames.MAINTENANCE,
			},
		],
	},
	errorRoutes,
];

export const asyncRoutes: RouterRecord[] = [
	// modules group
	lightingRoutes,
	parkingRoutes,
	wasteRoutes,
	broadcastRoutes,
	trafficRoutes,
	airQualityRoutes,
	informationMediaRoutes,
	chargingStationsRoutes,
	energyRoutes,
	environmentRoutes,
	routesRoutes,
	pointOfInterestRoutes,
	applicationsRoutes,
	eventsRoutes,
	ticketsRoutes,
	articleRoutes,
	camerasRoutes,
	alertsRoutes,
	// sharing group
	bikeSharingRoutes,
	scooterSharingRoutes,
	// admin group
	adminUserSettingsRoutes,
	adminSystemSettingsRoutes,
];

let router = createRouter({
	history: createWebHistory(),
	routes: [...constantRoutes, ...asyncRoutes] as RouteRecordRaw[],
});

export function resetRouter() {
	router = createRouter({
		history: createWebHistory(),
		routes: [...constantRoutes, ...asyncRoutes] as RouteRecordRaw[],
	});
}

export const paginationQueryDefault = { page: 1, limit: 15 };

export default router;
