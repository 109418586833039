import { request } from '@/utils/request';
import type { UserInfo } from '@/types/store/user';

export const login = async ({ password, email }: UserInfo) => request<any, {
	token_type: string;
	expires_in: number;
	access_token: string;
	refresh_token: string;
}>({
	url: '/auth/token',
	method: 'post',
	data: {
		grant_type: 'password',
		client_id: import.meta.env.VITE_APP_API_AUTH_PASSWORD_CLIENT_ID as string,
		client_secret: import.meta.env.VITE_APP_API_AUTH_PASSWORD_SECRET as string,
		username: email,
		password,
		scope: import.meta.env.VITE_APP_API_AUTH_GLOBAL_SCOPE as string,
	},
});

export async function getInfo<Response = any>(): Promise<Response> {
	return request({
		url: '/auth/user',
		method: 'get',
	});
}

export async function logout<Response = any>(headers: { Authorization: string }): Promise<Response> {
	return request({
		url: '/auth/logout',
		method: 'get',
		headers,
	});
}
