enum StorageKey {
	ACCESS = 'access-token',
	REFRESH = 'refresh-token',
	LOGIN = 'login-redirect',
}

export const getAccessToken = () => localStorage.getItem(StorageKey.ACCESS);
export const getRefreshToken = () => localStorage.getItem(StorageKey.REFRESH);

export const getHeaders = () => {
	const token = getAccessToken();
	if (!token) {
		throw new Error('No auth token available!');
	}

	return {
		Authorization: 'Bearer ' + token,
	};
};

export const setAuthTokens = (access_token: string, refresh_token: string) => {
	localStorage.setItem(StorageKey.ACCESS, access_token);
	localStorage.setItem(StorageKey.REFRESH, refresh_token);
};

export const removeAuthTokens = () => {
	localStorage.removeItem(StorageKey.ACCESS);
	localStorage.removeItem(StorageKey.REFRESH);
	localStorage.removeItem(StorageKey.LOGIN);
};

export const cantUserEnterLoginPage = () => localStorage.getItem(StorageKey.LOGIN);
export const setUserCantEnterLoginPage = () => {
	localStorage.setItem(StorageKey.LOGIN, 'true');
};

export const removeUserCantEnterLoginPage = () => {
	localStorage.removeItem(StorageKey.LOGIN);
};
