import Icon from '@/assets/icons/air.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const airQualityRoutes: RouterRecord = {
	path: '/air_quality',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'air_quality', icon: Icon, group: 'modules', id: UserModuleIdentifiers.AIR_QUALITY },
	name: RouteNames.AIR_QUALITY_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			name: RouteNames.AIR_QUALITY,
		},
	],
};
