import Icon from '@/assets/icons/bike_sharing.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const bikeSharingRoutes: RouterRecord = {
	path: '/bike_sharing',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/bike_sharing',
	meta: { title: 'bike_sharing', icon: Icon, group: 'sharing', hasHr: true, hasSideBarTitle: 'atk_sharing', id: UserModuleIdentifiers.BIKE_SHARING },
	name: RouteNames.BIKE_SHARING_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			name: RouteNames.BIKE_SHARING,
		},
	],
};
