import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import '@/assets/styles/index.scss';
import 'element-plus/theme-chalk/dark/css-vars.css';
import 'vue3-treeselect/dist/vue3-treeselect.css';
import 'element-plus/dist/index.css';
import App from '@/views/App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import i18n from '@/lang';
import '@/permission'; // Permission control
import vClickOutside from 'click-outside-vue3';
import { vHideForRoles, vPermission } from '@/utils/directives';
import CKEditor from '@ckeditor/ckeditor5-vue';

createApp(App)
	.directive('permission', vPermission)
	.directive('hide-for-roles', vHideForRoles)
	.use(vClickOutside as unknown)
	.use(router)
	.use(createPinia())
	.use(i18n)
	.use(ElementPlus)
	.use(CKEditor as unknown)
	.mount('#app');
