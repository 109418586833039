import router from '@/router';
import { cantUserEnterLoginPage, getAccessToken, removeAuthTokens } from '@/utils/auth';
import getPageTitle from '@/utils/get-page-title';
import { RouteAccessibility, useUserStore } from '@/store/user';
import { usePermissionStore } from '@/store/permission';
import type { RouterRecord } from '@/types/router';
import { notification } from '@/utils/notifications';
import { RouteNames } from '@/types/router';

const whiteList = ['/login', '/auth-redirect']; // No redirect whitelist

router.beforeEach(async (to, from, next) => {
	if (whiteList.includes(to.matched[0] ? to.matched[0].path : '')) {
		// In the free login whitelist, go directly
		next();
		return;
	}

	if (!getAccessToken()) {
		/* Has no token */
		// Other pages that do not have permission to access are redirected to the login page.
		removeAuthTokens();
		next(`/login?redirect=${to.path}`);
		return;
	}

	const userStore = useUserStore();
	const permissionStore = usePermissionStore();
	document.title = getPageTitle(to.meta.title as string);

	if ((!cantUserEnterLoginPage() || !userStore.id) && to.name !== RouteNames.E429) {
		try {
			await userStore.getInfo();
			const { roles, permissions } = userStore;

			await permissionStore.generateRoutes({ roles, permissions }).then((response: RouterRecord[]) => {
				response.forEach((route: RouterRecord) => router.addRoute(route));
			});
		} catch (error) {
			userStore.removeToken();
			notification('error', (error || 'Has Error') as string);
			next(`/login?redirect=${to.path}`);
			return;
		}
	}

	switch (userStore.isRouteAccessible(to as RouterRecord)) {
		case RouteAccessibility.ERROR_NOT_AUTHORIZED:
			next({ name: RouteNames.ERROR_401 });
			return;
		case RouteAccessibility.ERROR_AVAILABILITY:
			next({ name: RouteNames.MAINTENANCE });
			return;
		default:
			next();
	}
});

