import Icon from '@/assets/icons/track.svg';
import type { RouterRecord } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { RouteNames } from '@/types/router';

export const routesRoutes: RouterRecord = {
	path: '/routes',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/routes',
	meta: { title: 'routes', icon: Icon, group: 'modules', id: UserModuleIdentifiers.ROUTES },
	name: RouteNames.ROUTE_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/routes/RoutesIndex.vue'),
			name: RouteNames.ROUTE_DEFAULT,
			meta: { title: 'routes', icon: 'track' },
		},
		{
			path: 'form',
			component: async () => import('@/views/routes/RoutesForm.vue'),
			name: RouteNames.ROUTE_FORM,
			children: [
				{
					path: ':routes_id(\\d+)',
					component: async () => import('@/views/routes/RoutesForm.vue'),
					name: RouteNames.ROUTE_EDIT_FORM,
				},
			],
		},
	],
};
