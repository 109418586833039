import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { IconAlertTriangle } from '@tabler/icons-vue';
import { defineAsyncComponent } from 'vue';

export const alertsRoutes: RouterRecord = {
	path: '/alerts',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'alerts', icon: IconAlertTriangle, group: 'modules', id: UserModuleIdentifiers.ALERTS },
	name: RouteNames.ALERTS,
	children: [
		{
			path: '',
			component: defineAsyncComponent(async () => import('@/views/alerts/AlertsDashboard.vue')),
			name: RouteNames.ALERTS_DASHBOARD,
		},
	],
};
