import Icon from '@/assets/icons/water_quality.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { defineAsyncComponent } from 'vue';
import { getEnvironmentObjectRoutes } from '@/static/modern-tech';

export const environmentRoutes: RouterRecord = {
	path: '/environment',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'environment', icon: Icon, group: 'modules', id: UserModuleIdentifiers.ENVIRONMENT },
	name: RouteNames.ENVIRONMENT_ROOT,
	children: [
		{
			path: '',
			component: defineAsyncComponent(async () => import('@/views/environment/EnvironmentDashboard.vue')),
			name: RouteNames.ENVIRONMENT,
		},
		{
			path: 'zones/:id',
			component: defineAsyncComponent(async () => import('@/views/environment/EnvironmentZone.vue')),
			name: RouteNames.ENVIRONMENT_ZONE,
		},
		...getEnvironmentObjectRoutes(),
		{
			// Temporary fallback, going to be removed
			path: 'objects/:identifier',
			component: defineAsyncComponent(async () => import('@/views/environment/EnvironmentObjectWeatherStation.vue')),
			name: RouteNames.ENVIRONMENT_OBJECT,
		},
	],
};
