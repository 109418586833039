import Icon from '@/assets/icons/communication.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const informationMediaRoutes: RouterRecord = {
	path: '/informationMedia',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'informationMedia', icon: Icon, group: 'modules', id: UserModuleIdentifiers.INFORMATION_MEDIA },
	name: RouteNames.INFORMATION_MEDIA_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			name: RouteNames.INFORMATION_MEDIA,
		},
	],
};
