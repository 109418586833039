import Icon from '@/assets/icons/charging_stations.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const chargingStationsRoutes: RouterRecord = {
	path: '/charging_stations',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/charging-stations',
	meta: { title: 'charging_stations', icon: Icon, group: 'modules', id: UserModuleIdentifiers.CHARGING_STATIONS },
	name: RouteNames.CHARGING_STATIONS_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			name: RouteNames.CHARGING_STATIONS,
		},
	],
};
