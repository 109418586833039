import { useUserStore } from '@/store/user';

export const vPermission = {
	mounted(el: HTMLElement, binding: unknown) {
		const userStore = useUserStore();
		const { value } = binding;

		if (!value || !Array.isArray(value) || !value.length) {
			console.error('Permissions are required! Example: v-permission="[\'manage user\',\'manage permission\']"');
			return;
		}

		if (!userStore.permissions.some(perm => value.includes(perm))) {
			el.remove();
		}
	},
};

export const vHideForRoles = {
	mounted(el: HTMLElement, binding: unknown) {
		const userStore = useUserStore();
		const { value } = binding;

		if (!value || !Array.isArray(value) || !value.length) {
			console.error('Permissions are required! Example: v-hide-for-roles="[\'manage user\',\'manage permission\']"');
			return;
		}

		if (userStore.roles.some(perm => value.includes(perm))) {
			el.remove();
		}
	},
};
