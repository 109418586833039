import Icon from '@/assets/icons/articles.svg';
import type { RouterRecord } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { RouteNames } from '@/types/router';

export const articleRoutes: RouterRecord = {
	path: '/articles',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/articles',
	meta: { title: 'articles', icon: Icon, group: 'modules', id: UserModuleIdentifiers.ARTICLES },
	name: RouteNames.ARTICLE_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/articles/ArticlesIndex.vue'),
			name: RouteNames.ARTICLE_DEFAULT,
			meta: { title: 'articles', icon: 'articles', root: true },
			children: [
				{
					path: 'locality/:locality_id(\\d+)',
					component: async () => import('@/views/articles/ArticlesIndex.vue'),
					name: RouteNames.ARTICLE_LOCALITY,
				},
			],
		},
		{
			path: ':article_id',
			component: async () => import('@/views/articles/ArticlesFormRoot.vue'),
			name: RouteNames.ARTICLE_EDIT_FORM,
			meta: { edit: true },
		},
		{
			path: 'locality/:locality_id(\\d+)/create',
			component: async () => import('@/views/articles/ArticlesFormRoot.vue'),
			name: RouteNames.ARTICLE_ADD_FORM,
			meta: { edit: false },
		},
		{
			path: 'create',
			component: async () => import('@/views/articles/ArticlesFormRoot.vue'),
			name: RouteNames.ARTICLE_ROOT_ADD_FORM,
			meta: { edit: false },
		},
	],
};
