<script setup lang="ts">
// @ts-ignore
import sk from 'element-plus/dist/locale/sk.mjs';
// @ts-ignore
import en from 'element-plus/dist/locale/en.mjs';
import { computed } from 'vue';
import { useAppStore } from '@/store/app';

const appStore = useAppStore();

const skCustomLocale = {
	...sk,
	el: {
		...sk.el,
		pagination: {
			...sk.el.pagination,
			total: 'Celkovo: {total}',
			pagesize: ' na stranu',
		},
	},
};

appStore.setMode();

const locale = computed(() => appStore.language === 'en' ? en : skCustomLocale);
</script>

<template>
	<el-config-provider :locale="locale">
		<router-view />
	</el-config-provider>
</template>
