import Icon from '@/assets/icons/tickets.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const ticketsRoutes: RouterRecord = {
	path: '/tickets',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'tickets', icon: Icon, group: 'modules', id: UserModuleIdentifiers.TICKETS },
	name: RouteNames.TICKETS_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/tickets/TicketsIndex.vue'),
			name: RouteNames.TICKETS_DEFAULT,
			meta: { title: 'tickets', icon: 'tickets', root: true },
			children: [
				{
					path: 'locality/:locality_id(\\d+)',
					component: async () => import('@/views/tickets/TicketsIndex.vue'),
					name: RouteNames.TICKETS_LOCALITY,
				},
			],
		},
		{
			path: 'detail/:event_id',
			component: async () => import('@/views/tickets/TicketEventDetail.vue'),
			name: RouteNames.TICKETS_DETAIL,
		},
		{
			path: 'seasons/:locality_id?',
			component: async () => import('@/views/tickets/SeasonTickets.vue'),
			name: RouteNames.TICKETS_SEASONS,
			meta: { title: 'seasonTickets' },
		},
		{
			path: 'seasons/create',
			component: async () => import('@/views/tickets/SeasonTicketCreate.vue'),
			name: RouteNames.TICKETS_CREATE,
		},
	],
};
