import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';

export const errorRoutes: RouterRecord = {
	path: '/error',
	component: async () => import('@/layout/LayoutIndex.vue'),
	name: RouteNames.ERROR_ROOT,
	meta: { title: 'errorPages', icon: '404' },
	children: [
		{
			path: '401',
			component: async () => import('@/views/error-page/UnAuthorized401.vue'),
			name: RouteNames.ERROR_401,
			meta: { title: 'page401' },
		},
		{
			path: '404',
			component: async () => import('@/views/error-page/NotFound404.vue'),
			name: RouteNames.ERROR_404,
			meta: { title: 'page404' },
		},
	],
};

