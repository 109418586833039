import type { Module, UserModuleIdentifiers } from '@/types/store/user';
import { defineStore } from 'pinia';
import { useUserStore } from '@/store/user';
import { asyncRoutes, constantRoutes } from '@/router';
import type { RouterRecord } from '@/types/router';

interface Permission {
	routes: RouterRecord[];
	addRoutes: string[];
}

interface GenRoutesProps {
	roles: string[];
	permissions: string[];
}

const canAccess = (roles: string[], permissions: string[], route: RouterRecord): boolean => {
	if (route.meta) {
		let hasRole = true;
		let hasPermission = true;
		if (route.meta.roles ?? route.meta.permissions) {
			// If it has meta.roles or meta.permissions, accessible = hasRole || permission
			hasRole = false;
			hasPermission = false;
			if (route.meta.roles) {
				hasRole = roles.some(role => route?.meta?.roles.includes(role));
			}

			if (route.meta.permissions) {
				hasPermission = permissions.some(permission => route.meta.permissions.includes(permission));
			}
		}

		return hasRole || hasPermission;
	}

	// If no meta.roles/meta.permissions inputted - the route should be accessible
	return true;
};

const filterAsyncRoutes = (routes: RouterRecord[], roles: string[], permissions: string[]): RouterRecord[] => {
	const res: RouterRecord[] = [];

	routes.forEach(route => {
		const tmp = { ...route };
		if (canAccess(roles, permissions, tmp)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(
					tmp.children,
					roles,
					permissions,
				);
			}

			res.push(tmp);
		}
	});

	return res;
};

const findModule = (moduleName: UserModuleIdentifiers, moduleList: Module[]): Module | undefined => {
	moduleList.forEach(module => {
		if (module?.id_string === moduleName) {
			return module;
		}
	});
	return undefined;
};

const serializeModuleRoutes = (routes: RouterRecord[], modules: Module[], isAdmin: boolean): RouterRecord[] => {
	const accessedRoutes: RouterRecord[] = [];
	routes.forEach(route => {
		let moduleFind: Module | undefined;
		if (route.module) {
			moduleFind = findModule(route.module as UserModuleIdentifiers, modules);
		}

		if (moduleFind) {
			if (!route.meta) {
				Object.assign(route, { meta: {} });
			}

			Object.assign(route.meta, { module: moduleFind });
		}

		if (isAdmin || !moduleFind || moduleFind.type === 'VIEW' || moduleFind.type === 'MANAGE') {
			accessedRoutes.push(route);
		}
	});
	return accessedRoutes;
};

export const usePermissionStore = defineStore('permission', {
	state: (): Permission => ({
		routes: [],
		addRoutes: [],
	}),

	actions: {
		async generateRoutes({ roles, permissions }: GenRoutesProps) {
			const userStore = useUserStore();
			return new Promise<RouterRecord[]>(resolve => {
				let accessedRoutes: RouterRecord[];
				const { modules } = userStore;
				if (roles.includes('superadmin')) {
					accessedRoutes = asyncRoutes;
				} else {
					accessedRoutes = filterAsyncRoutes(asyncRoutes, roles, permissions);
				}

				accessedRoutes = serializeModuleRoutes(accessedRoutes, Object.values(modules), roles.includes('admin'));
				this.addRoutes = accessedRoutes;
				this.routes = constantRoutes.concat(accessedRoutes);
				resolve(accessedRoutes);
			});
		},
	},
});
