import { defineStore } from 'pinia';
import { getLanguage } from '@/lang';
import Cookies from 'js-cookie';

export enum Theme {
	DARK = 'dark',
	LIGHT = 'light',
	SYSTEM = 'system',
}
interface AppState {
	language: 'sk' | 'en';
	theme: Theme;
	isDark: boolean;
}

export const useAppStore = defineStore('app', {
	state: (): AppState => ({
		language: getLanguage(),
		theme: localStorage.theme === Theme.SYSTEM ? Theme.DARK : localStorage.theme as Theme,
		isDark: false,
	}),

	actions: {
		setLanguage(language: 'sk' | 'en') {
			this.language = language;
			Cookies.set('language', language);
		},
		toggleMode(theme: Theme) {
			this.theme = theme;

			if (theme === Theme.SYSTEM) {
				localStorage.removeItem('theme');
			} else {
				localStorage.setItem('theme', theme);
			}

			if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
				document.documentElement.classList.add('dark');
				this.isDark = true;
			} else {
				document.documentElement.classList.remove('dark');
				this.isDark = false;
			}
		},
		setMode() {
			if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
				document.documentElement.classList.add('dark');
				this.theme = localStorage.theme === Theme.DARK ? Theme.DARK : Theme.SYSTEM;
				this.isDark = true;
			} else {
				document.documentElement.classList.remove('dark');
				this.theme = localStorage.theme === Theme.LIGHT ? Theme.LIGHT : Theme.SYSTEM;
				this.isDark = false;
			}
		},
	},
});
