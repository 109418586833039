import Icon from '@/assets/icons/cameras.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const trafficRoutes: RouterRecord = {
	path: '/traffic',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'traffic', icon: Icon, group: 'modules', id: UserModuleIdentifiers.TRAFFIC },
	name: RouteNames.TRAFFIC_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/traffic/TrafficDashboard.vue'),
			name: RouteNames.TRAFFIC_DASHBOARD,
		},
		{
			path: 'paths/:id',
			component: async () => import('@/views/traffic/TrafficPath.vue'),
			name: RouteNames.TRAFFIC_PATH,
		},
		{
			path: 'control-points/:id',
			component: async () => import('@/views/traffic/TrafficControlPoint.vue'),
			name: RouteNames.TRAFFIC_CONTROL_POINT,
		},
	],
};
