import Icon from '@/assets/icons/parking.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { defineAsyncComponent } from 'vue';

export const parkingRoutes: RouterRecord = {
	path: '/parking',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'parking', icon: Icon, group: 'modules', id: UserModuleIdentifiers.PARKING },
	name: RouteNames.PARKING_ROOT,
	children: [
		{
			path: '',
			component: defineAsyncComponent(async () => import('@/views/parking/ParkingDefaultIndex.vue')),
			name: RouteNames.PARKING_DEFAULT_INDEX,
		},
		{
			path: 'zones/:zoneIdentifier',
			component: defineAsyncComponent(async () => import('@/views/parking/ParkingZoneIndex.vue')),
			name: RouteNames.PARKING_ZONE_INDEX,
		},
		{
			path: 'places/:id',
			component: defineAsyncComponent(async () => import('@/views/parking/ParkingPlaceIndex.vue')),
			name: RouteNames.PARKING_PLACE_INDEX,
		},
	],
};
