import type { AxiosError } from 'axios';
import axios from 'axios';
import { getAccessToken, getRefreshToken, removeUserCantEnterLoginPage, setAuthTokens } from '@/utils/auth';
import router from '@/router';
import type { Auth } from '@/types/api';
import createAuthRefreshInterceptor from '@antik-web/axios-auth-refresh-response-data';
import i18n from '@/lang';
import { notification } from '@/utils/notifications';
import { RouteNames } from '@/types/router';

const { t } = i18n.global;

const axiosInstance = axios.create({
	baseURL: import.meta.env.VITE_APP_API_URL as string,
	timeout: 10000, // Request timeout
});

axiosInstance.interceptors.request.use(
	config => {
		const token = getAccessToken();

		if (token) {
			config.headers.Authorization = 'Bearer ' + token; // Set JWT token
		}

		return config;
	},
	error => {
		// Do something with request error
		console.error(error); // For debug
		void Promise.reject(error);
	},
);

const refreshAuthLogic = async (failedRequest: AxiosError): Promise<void> => {
	await handleTokenAuth(failedRequest);
};

const refreshTokens = async (): Promise<{
	access_token: string;
	refresh_token: string;
}> => {
	const { access_token, refresh_token } = await axiosInstance.post('/auth/token', {
		grant_type: 'refresh_token',
		refresh_token: getRefreshToken(),
		client_id: import.meta.env.VITE_APP_API_AUTH_PASSWORD_CLIENT_ID as string,
		client_secret: import.meta.env.VITE_APP_API_AUTH_PASSWORD_SECRET as string,
		scope: '*',
	}) as unknown as Auth;
	setAuthTokens(access_token, refresh_token);
	return { access_token, refresh_token };
};

export const handleTokenAuth = async (res: AxiosError) => {
	try {
		if (router.currentRoute.value.name === 'login') {
			return;
		}

		const { access_token } = await refreshTokens();
		res.response!.config.headers.Authorization = 'Bearer ' + access_token;
		await Promise.resolve();
	} catch (err: any) {
		removeUserCantEnterLoginPage();
		await router.push({ name: RouteNames.LOGIN });
		notification('error', t('errorlog.logoutToken'));
	}
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, { pauseInstanceWhileRefreshing: true });

export const request = axiosInstance;

axiosInstance.interceptors.response.use(
	null,
	async (error: AxiosError) => {
		if (error?.response?.status === 429 && (error.request as XMLHttpRequest).responseURL.search(/ping|token/) === -1) {
			await router.push({ name: RouteNames.E429 });
		}

		return Promise.reject(error);
	},
);

export const pingServer = async (url: string) => axiosInstance.get(url);
