import { ElNotification as elNotification } from 'element-plus';

export const notification = (type: 'success' | 'error' | 'info' | 'warning', message: string, duration = 4000) => {
	elNotification({
		message,
		type,
		customClass: `${type}-notification`,
		position: 'bottom-left',
		duration,
	});
};
