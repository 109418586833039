import Icon from '@/assets/icons/lighting.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const lightingRoutes: RouterRecord = {
	path: '/lighting',
	component: async () => import('@/layout/LayoutIndex.vue'),
	name: RouteNames.LIGHTING_ROOT,
	meta: { title: 'lighting', icon: Icon, group: 'modules', hasHr: true, id: UserModuleIdentifiers.LIGHTING },
	children: [
		{
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			path: '',
			name: RouteNames.LIGHTING,
		},
	],
};
